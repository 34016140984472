import React from "react"
import Img from "gatsby-image"

import { Layout } from "../components/layout"
import { graphql } from "gatsby"

export default ({ data }) => {
  return (
    <Layout>
      <div className="p-8 container mx-auto">
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    ...SELF_IMAGE_QUERY
  }
`
